<template>

        <vue-final-modal class="iu-modal" v-slot="{ params, close }" v-bind="$attrs" classes="iu-modal-container" :content-class="[contentClass]">

            <!-- Modal header -->
            <div class="h-20 py-4 px-6 flex items-center justify-between w-full" :class="[`bg-${headerColor}`]">
                <h3 class="font-bold text-xl text-black uppercase">
                    <slot name="title"></slot>
                </h3>
                <div role="button" v-if="!props.hideClose"
                    class="focus:outline-none focus:ring-1 focus:ring-blue-dark p-2"
                    tabindex="0"
                    @click="$emit('closed', close)"><svg-icon name="close" class="text-lg font-bold" />
                </div>
            </div>

            <!-- Modal body -->
            <div class="flex flex-col overflow-hidden overflow-y-auto px-2 py-4 md:px-6 bg-gray-50 max-h-full" :style="dynamicContent ? '' : 'height: 58vh'">
                <slot :params="params"></slot>
            </div>

            <!-- Modal footer -->
            <div class="modal__footer" v-if="!hideFooter">
                <button @click="$emit('confirm', close)">confirm</button>
                <button @click="$emit('cancel', close)">cancel</button>
            </div>
        </vue-final-modal>

</template>

<script lang="ts">
export default {
    inheritAttrs: false,
}
</script>

<script setup lang="ts">

import SvgIcon from "@/components/SvgIcon.vue";
import {computed} from "vue";
import { useRoute } from "vue-router";

const route = useRoute()

const emit = defineEmits(['confirm', 'cancel'])

const props = defineProps<{
    contentClass?: string,
    dynamicContent?: boolean,
    hideFooter?: boolean,
    color?: string,
    hideClose?: boolean
}>()

const headerColor = computed(() => props.color ?? (route.meta.color ?? 'orange'))

</script>

<style>

.iu-modal-container {
    @apply flex justify-center items-center z-50
}

/*.iu-modal :deep(.modal-container) {*/
/*    display: flex;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*}*/
/*.iu-modal :deep(.modal-content) {*/
/*    position: relative;*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    max-height: 90%;*/
/*    margin: 0 1rem;*/
/*    padding: 1rem;*/
/*    border: 1px solid #e2e8f0;*/
/*    border-radius: 0.25rem;*/
/*    background: #fff;*/
/*}*/
/*.modal__title {*/
/*    margin: 0 2rem 0 0;*/
/*    font-size: 1.5rem;*/
/*    font-weight: 700;*/
/*}*/
/*.modal__content {*/
/*    flex-grow: 1;*/
/*    overflow-y: auto;*/
/*}*/
/*.modal__action {*/
/*    display: flex;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*    flex-shrink: 0;*/
/*    padding: 1rem 0 0;*/
/*}*/
/*.modal__close {*/
/*    position: absolute;*/
/*    top: 0.5rem;*/
/*    right: 0.5rem;*/
/*}*/
</style>