import RouteMeta from "@/types/RouteMeta";

const dsUnitRoutes = [
    {
        path: import.meta.env.VITE_MODE === 'local' || window.location.href.includes(import.meta.env.VITE_B2C_BASE_URL as string) ? '/' : '/ds',
        name: 'ds',
        redirect: () => ({
            name: "ds.home"
        }),
        component: () => import('./Index.vue'),
        meta: {
            unitRoute: true,
            onboarding: 'ds.onboarding',
            navIcon: 'user',
        },
        children: [
            {
                path: 'onboarding',
                name: 'ds.onboarding',
                component: () => import('./views/DsOnboarding.vue'),
                meta: {
                    title: 'Onboarding',
                    navItem: false,
                    noNav: true,
                    color: 'orange',
                    backToTopDisabled: true,
                } as RouteMeta,
            },
            {
                path: 'home',
                name: 'ds.home',
                component: () => import('./views/DsHome.vue'),
                meta: {
                    title: 'Home',
                    icon: 'home',
                    topNav: false,
                } as RouteMeta,
            },
            {
                path: 'application/abilities',
                name: 'ds.application.abilities',
                component: () => import('./views/applications/abilities/Abilities.vue'),
                meta: {
                    color: 'yellow',
                    topNav: false,
                    title: 'Dein Termin mit der Studienberatung',
                    icon: 'user-cog',
                    step: 1,
                } as RouteMeta,
                children: [
                    {
                        path: '1',
                        name: 'ds.application.abilities.studyinfo',
                        component: () => import('./views/applications/abilities/Step1_StudyInfo.vue'),
                    },
                    {
                        path: '2',
                        name: 'ds.application.abilities.studyadvice',
                        component: () => import('./views/applications/abilities/Step2_StudyAdvice.vue'),
                    }
                ]
            },
            {
                path: 'study-match',
                redirect: () => ({
                    name: "ds.tips"
                }),
                meta: {},
            },
            {
                path: 'application/search',
                name: 'ds.application.search',
                component: () => import('./views/applications/search/Search.vue'),
                meta: {
                    color: 'blue',
                    topNav: false,
                    title: 'Praxispartner finden',
                    icon: 'handshake',
                    step: 2,
                } as RouteMeta,
                children: [
                    {
                        path: '1',
                        name: 'ds.application.search.graduation',
                        component: () => import('./views/applications/search/Step1_Graduation.vue'),
                    },
                    {
                        path: '2',
                        name: 'ds.application.search.distance',
                        component: () => import('./views/applications/search/Step2_Distance.vue'),
                    },
                    {
                        path: '3',
                        name: 'ds.application.search.motivation',
                        component: () => import('./views/applications/search/Step3_Motivation.vue'),
                    },
                    {
                        path: '4',
                        name: 'ds.application.search.documents',
                        component: () => import('./views/applications/search/Step4_Documents.vue'),
                    },
                    {
                        path: '5',
                        name: 'ds.application.search.selfmatching',
                        component: () => import('./views/applications/search/Step5_Selfmatching_Overview.vue'),
                    },
                ]
            },
            {
                path: 'application/contracts',
                name: 'ds.application.contracts',
                component: () => import('./views/applications/contracts/Contracts.vue'),
                meta: {
                    color: 'green',
                    topNav: false,
                    title: 'Einschreibung',
                    icon: 'sign',
                    step: 3,
                } as RouteMeta,
                children: [
                    {
                        path: '1',
                        name: 'ds.application.contracts.personal',
                        component: () => import('./views/applications/contracts/Step1_Personal.vue'),
                    },
                    {
                        path: '2',
                        name: 'ds.application.contracts.graduation',
                        component: () => import('./views/applications/contracts/Step2_Graduation.vue'),
                    },
                    {
                        path: '3',
                        name: 'ds.application.contracts.documents',
                        component: () => import('./views/applications/contracts/Step3_Documents.vue'),
                    },
                ]
            },
            {
                path: 'profile',
                name: 'ds.profile',
                redirect: () => ({
                    name: "ds.profile.applications"
                }),
                component: () => import('./views/profile/Profile.vue'),
                meta: {
                    color: 'orange',
                    topNav: true,
                    title: 'Dein Profil',
                    icon: 'user',
                    step: 4,
                } as RouteMeta,
                children: [
                    {
                        path: '1',
                        name: 'ds.profile.applications',
                        component: () => import('./views/profile/Step1_Applications.vue'),
                    },
                    {
                        path: '2',
                        name: 'ds.profile.partner',
                        component: () => import('./views/profile/Step2_Partner.vue'),
                    },
                    {
                        path: '3',
                        name: 'ds.profile.personal',
                        component: () => import('./views/profile/Step3_Personal.vue'),
                    },
                    {
                        path: '4',
                        name: 'ds.profile.contact',
                        component: () => import('./views/profile/Step4_Contact.vue'),
                    },
                ]
            },
            {
                path: 'contact',
                name: 'ds.contact',
                redirect: () => ({
                    name: "ds.contact.advisor"
                }),
                component: () => import('./views/contact/Contact.vue'),
                meta: {
                    title: 'Kontakt',
                    icon: 'envelope',
                    color: 'orange',
                    topNav: true,
                } as RouteMeta,
                children: [
                    {
                        path: '1',
                        name: 'ds.contact.advisor',
                        component: () => import('./views/contact/Step1_Advisor.vue'),
                    },
                    {
                        path: '2',
                        name: 'ds.contact.team',
                        component: () => import('./views/contact/Step2_Team.vue'),
                    },
                    {
                        path: '3',
                        name: 'ds.contact.support',
                        component: () => import('./views/contact/Step3_Support.vue'),
                    },
                ]
            },
            {
                path: 'tips',
                name: 'ds.tips',
                component: () => import('./views/TipsView.vue'),
                meta: {
                    title: 'Tipps & Vorlagen',
                    icon: 'checklist',
                    navItem: true,
                    topNav: true,
                    topDivider: true
                } as RouteMeta
            },
            {
                path: 'faq',
                name: 'ds.faq',
                component: () => import('./views/FaqView.vue'),
                meta: {
                    title: 'Häufig gestellte Fragen',
                    icon: 'question-circle',
                    navItem: true,
                    topNav: true,
                } as RouteMeta,
            },
            {
                path: 'cvgenerator',
                name: 'ds.cvgenerator',
                component: () => import('./views/CvGeneratorView.vue'),
                meta: {
                    title: 'Profil Generator',
                    icon: 'question-circle',
                    // navItem: true,
                    // topNav: true,
                } as RouteMeta,
            },
            {
                path: 'bringafriend',
                name: 'ds.baf',
                component: () => import('./views/BringAFriendView.vue'),
                meta: {
                    title: 'Freunde werben',
                    icon: 'checklist',
                } as RouteMeta
            },
            {
                path: 'application/search/5/:jobOpeningId/details',
                name: 'ds.application.search.selfmatching.details',
                component: () => import('./views/applications/search/Step5_Selfmatching_Details.vue'),
                meta: {
                    color: 'blue',
                    topNav: false,
                    title: 'Praxispartner finden',
                    icon: 'handshake',
                    step: 2,
                } as RouteMeta,
            },
        ]
    },

]

export default dsUnitRoutes;
