import { reactive, readonly } from 'vue'
import apiService from '@/services/api.service'
import ApiError from '@/types/ApiError'
import appStore from '@/stores/app.store'
import { FrontendErrorCode } from '@/types/enums'
import { onBeforeRouteLeave } from 'vue-router'

const state = reactive({
  notificationCount: 0,

  subMenuActiveElement: '' as string,           // active sub/sidemenu eLement
  subMenuVisibleElements: [] as Array<string>,  // list of all visible sub/sidemenu elements

  showCompanySelectOverlay: false,
  companySelectError: null as any,

  newCreatedEmployee: null as {
    name: string,
    email: string,
  } | null,
});

const setSubMenuElement = (name:string, fullVisible:boolean) => {
  if(fullVisible) {
    state.subMenuVisibleElements.unshift(name)
  } else {
    state.subMenuVisibleElements = state.subMenuVisibleElements.filter(e => e !== name)
  }
  // console.log('state.subMenuVisibleElements', (JSON.parse(JSON.stringify(state.subMenuVisibleElements))))
  if(!state.subMenuVisibleElements) {
    return
  }
  state.subMenuActiveElement = state.subMenuVisibleElements[0] ?? name
}

const clearSubMenuElements = () => {
  state.subMenuActiveElement = ''
  state.subMenuVisibleElements = []
}

/** Reorder submenu - created for MPAD-886: if two elements are visible on the page, the last could be activated,
 * ro prevent this, reverse submenuelements if the first is not in place (triggered in onMounted with timeout)
**/
const reverseSubMenuElements = (firstElement?:string) => {
  if(firstElement && state.subMenuVisibleElements[0] === firstElement) {
    return;
  }
  state.subMenuVisibleElements.reverse()
  state.subMenuActiveElement = state.subMenuVisibleElements[0] ?? ''
  // console.log('state.subMenuActiveElement reorder', state.subMenuActiveElement)
}

const setShowCompanySelectOverlay = (val:boolean) => {
  state.showCompanySelectOverlay = val
}

const setCompanySelectError = (err:any) => {
  state.companySelectError = err
}

const setNewCreatedUser = (name:string|null, email:string|null) => {
  if(!name && !email) {
    state.newCreatedEmployee = null
    return
  }
  state.newCreatedEmployee = {
    name: name as string,
    email: email as string
  }
}

export default {
  state: readonly(state),
  setSubMenuElement,
  clearSubMenuElements,
  reverseSubMenuElements,
  setShowCompanySelectOverlay,
  setCompanySelectError,
  setNewCreatedUser,
};
