<template>
    <div class="mb-3 flex items-center">
        <!--suppress XmlInvalidId -->
        <label
            :id="`${props.name}-label`"
            :for="props.name"
            class="text-black font-bold flex items-center"><slot />
        </label>
        <span class="px-2">
          <svg-icon v-if="props.tooltipText" :content="props.tooltipText" name="info-circle" class="block w-6 h-6 md:w-5 pb-1 md:pb-0" />
        </span>
    </div>
</template>

<script setup lang="ts">
import SvgIcon from "@/components/SvgIcon.vue";

const props = defineProps<{
    name: string,
    errors?: Array<string>,
    tooltipText?: string,
}>()
</script>
