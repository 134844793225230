import {reactive, readonly} from "vue";
import AccountStoreData from "@/types/AccountStoreData";
import apiService from "@/services/api.service";
import unitStore from "@/stores/unit.store";
import ApiError from "@/types/ApiError";
import appStore from "@/stores/app.store";
import {FrontendErrorCode, MetadataKey} from "@/types/enums";

// Create store for account data from api-endpoint /account
const state = reactive({
    account: {} as AccountStoreData,
    languageOptions: [] as Array<string>,

    emailVerificationSend: {
        running: false,
        success: false,
        error: null,
        showModal: false,
    },

    noUnitAvailable: false,
});

const setAccountData = (data:AccountStoreData) => {
    console.log('[accountStore] (setAccountData)', state.account, data)

    // precheck graduation state MPAD-473
    data.universityEntranceQualificationBw = null
    if(data.universityEntranceQualification === 'Fachhochschulreife') {
        data.universityEntranceQualificationBw = false
    } else if(data.universityEntranceQualification === 'HighSchoolCertificateBadenWurttemberg') {
        data.universityEntranceQualification = 'Fachhochschulreife'
        data.universityEntranceQualificationBw = true
    }

    state.account = data;
}

const getPrimaryUnit = () => {
    const unit = state.account.units.find(unit => unit.primary === true);
    return unit?.id;
}

const fetchAccountData = async () => {
    // load account-data
    return await apiService.account.get(unitStore.state.activeUnitId).then((result) => {
        setAccountData(result.data);
    }, (error:ApiError) => {
        appStore.setApiError(error.code ?? FrontendErrorCode.FETCH_ERROR , error.message)
    })
}

const updateAccountData = async (data: object) => {
    return await apiService.account.patch(data, unitStore.state.activeUnitId).then((result) => {
        setAccountData(result.data);
    })
}

const updateMetadata = async (key: MetadataKey, value: any): Promise<any> => {
    state.account.metadata[key] = value

    return await apiService.account.putMetadata(state.account.metadata)
}

const getMetadataValue = (key: MetadataKey) => {
    return state.account.metadata[key]
}

const resetAccount = (): Promise<null> => {
    return apiService.apiClient.post('reset-me')
}

const setLanguageOptions = (options:Array<string>) => {
    state.languageOptions = options
}

const sendEmailVerification = async () => {
    state.emailVerificationSend.running = true
    state.emailVerificationSend.showModal = false
    return await apiService.account.sendMailVerification().then((result) => {
        state.emailVerificationSend.success = true
        setTimeout(() => {
            state.emailVerificationSend.success = false
        }, 3500)
    }, (error) => {
        state.emailVerificationSend.error = error.message ?? error
        setTimeout(() => {
            state.emailVerificationSend.error = null
        }, 5000)
    }).finally(() => {
        state.emailVerificationSend.running = false
    })
}

const showEmailVerificationModal = (newVal?:boolean) => {
    state.emailVerificationSend.showModal = newVal ?? true
}

const setUnitAvailable = (newVal:boolean) => {
    state.noUnitAvailable = newVal
}

const isDachRegion = ():(boolean|null) => {
    if(!state.account.shippingCountry) {
        return null
    }
    return ['DE', 'AT', 'CH', 'LI'].includes(state.account.shippingCountry)
}

const isDisabledByHzb = () => {
    const notExistingOrInProspect = !['Existing', 'In prospect'].includes(state.account.universityEntranceQualificationStatus || '')
    const abroad = state.account.universityEntranceQualification === 'University entrance diploma from abroad'
    const noDach = !isDachRegion()
    return notExistingOrInProspect && (abroad || noDach)
}

export default { state: readonly(state),
    setAccountData,
    getPrimaryUnit,
    fetchAccountData,
    updateAccountData,
    updateMetadata,
    getMetadataValue,
    setLanguageOptions,
    resetAccount,
    sendEmailVerification,
    showEmailVerificationModal,
    setUnitAvailable,
    isDachRegion,
    isDisabledByHzb,
};
