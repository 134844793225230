import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import appStore from "@/stores/app.store";
import {createI18n} from "vue-i18n";
import messages from "@intlify/vite-plugin-vue-i18n/messages"
import VueGtm from '@gtm-support/vue-gtm'
import {vueGtmOptions} from "@/services/gtm.service";
import sentryService from "@/services/sentry.service";
import vfmPlugin from 'vue-final-modal'
import FloatingVue from 'floating-vue'
// Swiper
import VueAwesomeSwiper from 'vue-awesome-swiper'

import './../node_modules/floating-vue/dist/style.css'
import './../node_modules/vue-multiselect/dist/vue-multiselect.css'
import './../node_modules/flatpickr/dist/flatpickr.min.css'
import './../node_modules/@vueform/slider/themes/default.css'
import './assets/styles/fonts.css'
import './assets/styles/index.css'
// Swiper
import 'swiper/css'
import 'swiper/css/pagination'

import 'virtual:svg-icons-register';

// @ts-ignore
import VueResizeObserver from "vue-resize-observer";

const i18n = createI18n({
    legacy: false,
    locale: appStore.state.languageCode,
    fallbackLocale: 'de',
    globalInjection: true,
    fallbackWarn: false,
    missingWarn: false,
    messages
})

const app = createApp(App)

app.use(router)
app.use(i18n)
// @ts-ignore
app.use(vfmPlugin)
// Swiper
app.use(VueAwesomeSwiper)
app.use(VueResizeObserver)

// FloatingVue Settings (tooltips, popovers, ...)
FloatingVue.options.themes.tooltip.html = true

app.use(FloatingVue)

if (import.meta.env.VITE_MODE !== 'local') {
    app.use(VueGtm, vueGtmOptions)
    sentryService.init(app)
}

app.config.globalProperties.$filters = {
  formattedDate(dateString: string): string {
    let date = new Date(dateString)
    return date.toLocaleDateString('de-DE', {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
    })
  },
}

app.mount('#app')
