const cloudinaryProfilFormatter = (cloudinaryUrl:string) : string => {

  const filterPart = 'c_thumb,g_face,h_500,w_500,z_0.7/r_max'

  const cloudinaryUrlPattern = /^https?:\/\/res\.cloudinary\.com\/.+$/i;
  if (!cloudinaryUrlPattern.test(cloudinaryUrl)) {
    console.log('No valid cloudinary url')
    return cloudinaryUrl
  }

  let parts = cloudinaryUrl.split('/');

  let uploadIndex = parts.indexOf('upload');
  if (uploadIndex !== -1) {
    let vIndex = parts.findIndex((part, index) => /^v\d+$/.test(part) && index > uploadIndex);
    if (vIndex !== -1) {
      parts.splice(uploadIndex + 1, vIndex - uploadIndex - 1, filterPart);
    } else {
      parts.splice(uploadIndex + 1, 0, filterPart);
    }
  }

  return parts.join('/');
}
export default cloudinaryProfilFormatter
