import accountStore from '@/stores/account.store'
import appStore from '@/stores/app.store'
import b2buserStore from "@/units/b2b/stores/b2buser.store";

declare global {
  interface Window {
    productFruits:any
    productFruitsUser:any
    _mfq:any
    UC_UI_SUPPRESS_CMP_DISPLAY:boolean|undefined
  }
}

export const activateProductFruits = (window: Window, document: Document, url: string, language: string) => {
  if (!appStore.state.productFruitLoaded) {
    appStore.setProductFruitLoaded(true)

    let productFruitId = (window.location.href.includes('enterprise')
        || window.location.href.includes('/b2b')
        || window.location.href.includes('unternehmensportal'))
        ? import.meta.env.VITE_PRODUCT_FRUITS_CODE_B2B
        : import.meta.env.VITE_PRODUCT_FRUITS_CODE_B2C

    window.productFruitsUser = {
      username: accountStore.state.account?.productFruitsId || accountStore.state.account?.uuid || b2buserStore.state.user?.productFruitsId || 'no-uuid'
    };
    window.productFruits = window.productFruits || {};
    window.productFruits.language = language;
    window.productFruits.code = productFruitId;
    let a = document.getElementsByTagName('head')[0];
    let r = document.createElement('script');
    r.async = true;
    r.src = url + '?c=' + productFruitId;
    a.appendChild(r);
  }
}

export const activateMouseflow = () => {
  if (!appStore.state.mouseflowLoaded) {

    let mouseflowId = (window.location.href.includes('enterprise')
      || window.location.href.includes('/b2b')
      || window.location.href.includes('unternehmensportal'))
      ? 'd22f1170-fe02-4c99-a8f3-ba493fef1065'
      : '0ab8167c-3dc2-425e-b5c0-692201ee3670'

    appStore.setMouseflowLoaded(true)
    window._mfq = window._mfq || [];
    let mf = document.createElement("script");
    mf.type = "text/javascript";
    mf.defer = true;
    mf.src = `//cdn.mouseflow.com/projects/${ mouseflowId }.js`;
    document.getElementsByTagName("head")[0].appendChild(mf);
  }
}

export const activateUsercentrics = () => {
  const usercentricsId = (window.location.href.includes('enterprise')
    || window.location.href.includes('/b2b')
    || window.location.href.includes('unternehmensportal'))
    ? 'yzBr-PNKx'
    : '4oM0nBM3V'
  if (usercentricsId && !window.location.search.includes('testmode')) {
    setTimeout(() => {
      const tag = document.createElement('script')

      tag.src = 'https://app.usercentrics.eu/browser-ui/latest/loader.js'
      tag.id = 'usercentrics-cmp'
      tag.setAttribute('data-settings-id', usercentricsId)
      tag.setAttribute('defer', 'true')
      tag.setAttribute(
        'lang',
        // @ts-ignore
        document.querySelector('html').getAttribute('lang')
      )
      // @ts-ignore
      document.querySelector('body').appendChild(tag)

      const reStyle = () => {
        if (
          // @ts-ignore
          !document
            .querySelector('#usercentrics-root')
            ?.shadowRoot.querySelector('[data-testid="uc-anchors"]')
        ) {
          setTimeout(reStyle, 100)

          return
        }
        // @ts-ignore
        const ucRoot = document.querySelector('#usercentrics-root').shadowRoot

        // @ts-ignore
        ucRoot.querySelector('[data-testid="uc-anchors"]').parentNode.style
          = 'margin-top: -5px;margin-left: 8px;margin-bottom: 20px;'

        // @ts-ignore
        ucRoot
          .querySelector('[data-testid="uc-footer"]')
          .insertAdjacentHTML(
            'beforeend',
            // @ts-ignore
            ucRoot.querySelector('[data-testid="uc-anchors"]').parentNode.outerHTML
          )

        // @ts-ignore
        ucRoot.querySelector('[data-testid="uc-anchors"]').parentNode.style.display = 'none'

        const ucStyle = document.createElement('link')
        ucStyle.href = '/styles/usercentrics.css'
        ucStyle.rel = 'stylesheet'
        ucStyle.type = 'text/css'
        // @ts-ignore
        ucRoot.appendChild(ucStyle)
      }

      reStyle()
    }, 2000)
  }
}

export const activateAbTasty = () => {
  if (!appStore.state.abTastyLoaded) {
    appStore.setAbTastyLoaded(true)
    let ab = document.createElement("script");
    ab.type = "text/javascript";
    ab.src = appStore.state.isB2b
        ? "https://try.abtasty.com/f2569426ef605601db7fccc945f7036b.js"
        : "https://try.abtasty.com/19ef19c343ab4f373c3a82dbcba02e7a.js";
    document.getElementsByTagName("head")[0].appendChild(ab);
  }
}

export const suppressUsercentricsConsentModal = () => {
  window.UC_UI_SUPPRESS_CMP_DISPLAY = true
}