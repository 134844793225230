
interface DataLayerObject {
    event: string;
    eventData: {
        'data-t4-eventname'?: string;
        'data-t4-label'?: string;
        'data-t4-type'?: string;
        'data-t4-position'?: string;
    };
}

/**
 *
 * @param eventname
 * @param label
 * @param type
 * @param position
 */
const pushToDataLayer = (eventname?: string|null, label?: string|null, type?: string|null, position?: string|null): void => {

    // check for null events (in components with optional tracking props)
    if(!eventname) {
        return
    }

    const eventData: DataLayerObject['eventData'] = {};

    eventData['data-t4-eventname'] = eventname;
    eventData['data-t4-label'] = label || '';
    eventData['data-t4-type'] = type || '';
    eventData['data-t4-position'] = position || '';

    const dataLayerObject: DataLayerObject = {
        event: 'customPush',
        eventData: eventData
    };

    window.dataLayer?.push(dataLayerObject);
};

const pushToDataLayerWithDataObj = (eventData: object): void => {
    const dataLayerObject: DataLayerObject = {
        event: 'customPush',
        eventData: eventData
    };

    window.dataLayer?.push(dataLayerObject);
};


export default {
    pushToDataLayer, pushToDataLayerWithDataObj,
}