import RouteMeta from "@/types/RouteMeta";

const generalRoutes = [
    {
        path: '/',
        redirect: () => ({
            name: import.meta.env.VITE_MODE === 'local' || window.location.href.includes(import.meta.env.VITE_B2C_BASE_URL_FALLBACK as string) ? 'ds' : 'b2b'
        }),
    },
    {
        path: '/datenschutz',
        name: 'Dataprotection',
        component: () => import('@/units/ds/views/Dataprotection.vue'),
        meta: {
            allowUnauthorized: true,
            navItem: false,
            title: 'Dataprotection',
            suppressCmp: true
        } as RouteMeta
    },
    {
        path: '/dataprotection',
        redirect: () => ({
          name: 'Dataprotection'
        }),
    },
    {
        path: '/impressum',
        name: 'Imprint',
        component: () => import('@/pages/ImprintView.vue'),
        meta: {
            allowUnauthorized: true,
            navItem: false,
            title: 'Imprint',
            suppressCmp: true
        } as RouteMeta
    },
    {
      path: '/imprint',
      redirect: () => ({
        name: 'Imprint'
      }),
    },
    {
        path: '/documents/:uuid/preview',
        name: 'DocumentsPreview',
        component: () => import('@/pages/DocumentPreviewView.vue'),
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'PageNotFound',
        component: () => import(/* webpackChunkName: "PageNotFound" */ '@/pages/PageNotFoundView.vue'),
        meta: {
            navItem: false,
            title: '404 - Seite nicht gefunden',
        }  as RouteMeta,
    },
]

export default generalRoutes;
