import appStore from "@/stores/app.store";

let lastScrollTop = 0
const body = document.querySelector('body')

const determineScrollDirection = () => {
  const scrollTop = (window.pageYOffset || document.documentElement.scrollTop) - (document.documentElement.clientTop || 0)

  appStore.setIsTopScrolled(scrollTop === 0)

  if (scrollTop > lastScrollTop) {
    appStore.setShowBackToTop(false)
  } else {
    appStore.setShowBackToTop(true)
  }

  lastScrollTop = scrollTop
}
export default determineScrollDirection
